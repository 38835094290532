<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Send Push Notification
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
            <div class="sk-three-bounce">
                <div class="sk-child sk-bounce1"></div>
                <div class="sk-child sk-bounce2"></div>
                <div class="sk-child sk-bounce3"></div>
            </div>
        </div>
        <b-row>
          <b-col sm="12">
            <!--<h6>Simple Form</h6>-->
            <b-form v-on:submit.prevent="onSubmit" novalidate>
              <b-form-group id="titleInputGroup1"
                            label="Title"
                            label-for="title">
                <b-form-input id="title"
                              type="text"
                              v-model.lazy.trim="$v.form.title.$model"
                              :state="chkState('title')"
                              aria-describedby="titleFeedback"
                              placeholder="Please Enter Notification Title"
                              autocomplete='given-name'
                              autofocus />
                <b-form-invalid-feedback id="titleFeedback">
                  <span v-if="!$v.form.title.maxLength">- Jumlah karakter judul notifikasi tidak boleh lebih dari 60 karakter!</span>
                  <span v-if="!$v.form.title.required">- Judul notifikasi tidak boleh kosong!</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="typeInputGroup1"
                            label="Type"
                            label-for="type">
                <b-form-select id="type"
                              :plain="true"
                              v-model.lazy.trim="$v.form.type.$model"
                              :state="chkState('type')"
                              :options="[{value: '', text:'Please select'},
                                                {value: 1, text: 'Other'},
                                                {value: 4, text: 'Play Store Update'}]"
                              aria-describedby="typeFeedback"
                              autocomplete='given-name'
                              autofocus />
                <b-form-invalid-feedback id="typeFeedback" v-for="(error , index) in errors.form.type" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="typeNotificationInputGroup1"
                            label="Type Notification"
                            label-for=" typeNofitication">
                <b-form-select id="typeNofitication"
                              :plain="true"
                              @change="changes"
                              v-model.lazy.trim="$v.form.typeNotification.$model"
                              :state="chkState('typeNotification')"
                              :options="[{value: '', text:'Please select'},
                                                {value: 'global', text: 'Global'},
                                                {value: 'custom', text: 'Custom'}]"
                              aria-describedby="typeNotificationFeedback"
                              autocomplete='given-name'
                              autofocus />
                <b-form-invalid-feedback id="typeNotificationFeedback" v-for="(error , index) in errors.form.typeNotification" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="contentInputGroup2"
                            label="Content"
                            label-for="content">
                <b-form-textarea
                  id="textarea-rows"
                  v-model.lazy.trim="$v.form.content.$model"
                  :state="chkState('content')"
                  placeholder="Enter Content notification ..."
                  aria-describedby="contentFeedback"
                  rows="8"
                ></b-form-textarea>
                <b-form-invalid-feedback id="contentFeedback">
                  <span v-if="!$v.form.content.maxLength">- Jumlah karakter kontent notifikasi tidak boleh lebih dari 191 karakter!</span>
                  <span v-if="!$v.form.content.required">- Konten tidak boleh kosong! </span>
                </b-form-invalid-feedback>
              </b-form-group>
              <div v-show="form.typeNotification == 'custom'">
                <div class="filter-bar ui basic segment grid">
                  <div class="ui form">
                    <div class="inline field">
                      <label>Search for:</label>
                        <input type="text" v-model="filterText" class="three wide column" placeholder="name or email">&nbsp;
                        <button class="btn btn-primary" @click="doFilter" type="button">Go</button>&nbsp;
                        <button class="btn btn-secondary" @click="resetFilter" type="button">Reset</button>&nbsp;
                    </div>
                  </div>
                </div>
                <vuetable ref="vuetable"
                  @vuetable:load-error="handleLoadError"
                  :api-url="apiUrl"
                  :http-options="HttpOptions"
                  :fields="fields"
                  pagination-path=""
                  :muti-sort="true"
                  :sort-order="sortOrder"
                  :append-params="moreParams"
                  @vuetable:pagination-data="onPaginationData">

                  <template slot="name-slot" slot-scope="prop">
                    <span>{{ prop.rowData.name }}</span>
                  </template>

                  <template slot="email-slot" slot-scope="prop">
                    <span>{{ prop.rowData.email }}</span>
                  </template>
                  </vuetable>
                <div class="vuetable-pagination ui basic segment grid">
                  <vuetable-pagination-info ref="paginationInfo"
                  ></vuetable-pagination-info>

                  <vuetable-pagination ref="pagination"
                    @vuetable-pagination:change-page="onChangePage"
                  ></vuetable-pagination>
                </div>
              </div>
              <b-button type="submit" variant="primary">
                Submit
              </b-button>
            </b-form>
            <br/>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'
import { validationMixin } from "vuelidate"
import { required, maxLength, } from "vuelidate/lib/validators"
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';


Vue.use(Vuetable);

let Users = [];


export default {
  name: "push-notification",
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
  },
  data() {
    return {
      form: {
        title: '',
        content: '',
        type: '',
        typeNotification: '',
      },
      filterText: '',
      seleceted: '',
      // selectedUsers: this.$refs.vuetable.selectedTo,
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
        form: {
          title: [],
          content: [],
          type: [],
          typeNotification: [],
          sendTo: ''
        },
      },
      isLoading: false,
      apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `end-user`,
      HttpOptions: {
        headers: {
          'Accept' : 'application/json',
          'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
        }
      },
      sortOrder: [
        {
          field: 'created_at',
          sortField: 'created_at',
          direction: 'desc'
        }
      ],
      moreParams: {},
      fields: [
        {
          name: VuetableFieldCheckbox,   // <----
          titleClass: 'center aligned',
          dataClass: 'center aligned'
        },
        {
          name: 'name-slot',
          sortField: 'name',
          width: '20%',
          title: 'Name',
          length: '10'
        },
        {
          name: 'email-slot',
          sortField: 'email',
          title: 'Email'
        },
        {
          name: 'gender',
          sortField: 'gender',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
          formatter: this.genderLabel
        },
        {
          name: 'birthday',
          sortField: 'birthday',
          title: 'Date of Birth',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
          formatter: this.formatDate('DD-MM-YYYY')
        },
        {
          name: 'created_at',
          sortField: 'created_at',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
          title: 'Register Date'
        }
      ]
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      title: {
        required,
        maxLength: maxLength(60)
      },
      content: {
        required,
        maxLength: maxLength(191)
      },
      type: {
        required
      },
      typeNotification: {
        required
      }
    }
  },
  mounted() {
    this.$events.$on('filter-set', eventData => this.onFilterSet(eventData))
    this.$events.$on('filter-reset', () => this.onFilterReset())
  },
  methods: {
    genderLabel (value) {
      if (value == null) {
        return '<span class="ui red label">Unknown</span>'
      }else if(value == 'Male'){
        return '<span class="ui teal label"><i class="large man icon"></i>Male</span>'
      }else{
        return '<span class="ui pink label"><i class="large woman icon"></i>Female</span>'
      }
    },
    formatDate(value, fmt = 'D MM YYYY') {
      return (value == null)
        ? '-'
        : this.moment(value, 'YYYY-MM-DD').format(fmt)
    },
    doFilter () {
      this.$events.$emit('filter-set', this.filterText)
    },
    resetFilter () {
      this.filterText = ''  // clear the text in text input
      this.$events.$emit('filter-reset')
    },
    handleLoadError(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem('access_token') != null) {
          localStorage.removeItem('access_token');
          this.$swal.fire(
            'Your session expired!',
            'Your session has expired. Please login again to access this page!',
            'error'
          ).then(() =>{
            this.$router.push("/login")
          })
        }
      }else if(this.errors.code == 403) {
        this.$router.push("/403")
      }else if(this.errors.code == 500) {
        this.$router.push("/500")
      }
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    onFilterSet (filterText) {
        this.moreParams = {
            'filter': filterText
        }
        Vue.nextTick( () => this.$refs.vuetable.refresh())
    },
    onFilterReset () {
      this.moreParams = {}
      Vue.nextTick( () => this.$refs.vuetable.refresh())
    },
    onSubmit() {
      this.validate()
      if (this.form.typeNotification == 'custom') {
        this.isLoading = true
        Users = this.$refs.vuetable.selectedTo
        this.$http.post(`send-notif`, {
          title: this.form.title,
          notificationType: this.form.type,
          content: this.form.content,
          sendTo: this.form.typeNotification,
          users: Users
        }).then(() => {
          this.isLoading = false
            this.$swal.fire(
                'Success!',
                'Notification was successfully saved.',
                'success'
            ).then(() =>{
                location.reload()
            })
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
            if(this.errors.status == 422) {
              this.errors.message = error.response.data.meta.message;
              this.errors.form.title = this.errors.message.title;
              this.errors.form.content = this.errors.message.content;
              this.errors.form.type = this.errors.message.notificationType;
              this.errors.form.typeNotification = this.errors.message.sendTo;
              if (typeof this.errors.message.users != 'undefined') {
                this.$swal.fire(
                    'Failed!',
                    this.errors.message.users[0],
                    'error'
                )
              }
            }
          }
        })
      }else{
        this.isLoading = true
        this.$http.post(`send-notif`, {
          title: this.form.title,
          notificationType: this.form.type,
          content: this.form.content,
          sendTo: this.form.typeNotification
        }).then(() => {
          this.isLoading = false
            this.$swal.fire(
                'Success!',
                'Notification was successfully saved.',
                'success'
            ).then(() =>{
                location.reload()
            })
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
            if(this.errors.status == 422) {
              this.errors.message = error.response.data.meta.message;
              this.errors.form.title = this.errors.message.title;
              this.errors.form.content = this.errors.message.content;
              this.errors.form.type = this.errors.message.notificationType;
              this.errors.form.typeNotification = this.errors.message.sendTo;
            }
          }
        })
      }
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    },
    changes() {
      if (this.form.typeNotification == 'global') {
         this.$refs.vuetable.selectedTo = []
      }
    }
  }
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}

.invalid-feedback {
    font-size: 100% !important;
}
#contentFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
